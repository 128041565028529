import { AppBar, Box, Button, Dialog, Divider, IconButton, Input, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../src/images/logo.png'
import new_logo from '../../src/images/new_logo.png'
import { makeStyles } from "@mui/styles";
import hdebg from '../../src/images/hdebg.png'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';
import modalbg from '../../src/images/modalbg.png'

const useStyles = makeStyles({
    main___Div: {
        position:"sticky",
        zIndex:"1000",
        // paddingTop:"2rem",
        top:"0",
        background: `url(${hdebg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1.2rem 1.5rem 1rem 1.5rem',
        '@media(max-width : 900px)': {
            display: 'none'
        }
    },
    logo: {
        display: 'flex',

        gap: '0.5rem',
        textDecoration: 'none',
        '@media(max-width : 600px)': {
            alignItems: 'center',
            gap: '5px', 
        }
    },
    schedule__btn: {
        // color: '#fff',
        fontSize:"18px",
        color: '#0c69b8',
        // borderRadius:"12px",
        // fontSize:"16px",
        textDecoration: 'none',
        fontFamily: 'Candara',
        // fontWeight:"600",
        // background:" linear-gradient(to left, #0c69b8, rgba(101, 196, 133, 8))",
        fontWeight:"600", 
        // background: "#0c69b8",
        // border: "1px solid green",
        
        // padding:"1rem 2rem"
    },

    logo__cont: {
        textAlign: 'left',
        color: '#113a5a',

    },
    h6: {
        fontFamily: 'Candara !important',
    },
    p: {
        fontSize: '14px !important',
        fontFamily: 'Candara !important',
    },

    mobresp: {
        display: 'none',
        '@media(max-width : 900px)': {
            display: 'block',

        }
    },
    mobhdr: {
        background: `url(${hdebg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 2rem 1rem 2rem',
        '@media(max-width : 600px)': {
            padding: '1rem 0.6rem',
        }
    },
    mobtoolbar: {
        justifyContent: 'end',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%) !important',
        '@media(max-width : 900px)': {
            padding: '10px !important'
        }
    },
    mobappbar: {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 4%), 0px 4px 5px 0px rgb(0 0 0 / 2%), 0px 1px 10px 0px rgb(0 0 0 / 2%) !important'
    },
    hdrlistbox2: {

        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 2rem 1rem 2rem',
        position: 'relative'
    },
    navendmob: {
        position: 'absolute',
        textAlign: 'center',
        top: '2rem',
        zIndex: '11000'
    },
    logotop: {
        width: '50px'
    }
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});

const Header = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Box className={classes.main___Div}>
                <Box>
                    <Link className={classes.logo} to='/'>
                    <Box component={'img'} src={new_logo} sx={{
                        width:"240px"
                    }} />
                        {/* <Box>
                            <Box className={classes.logotop} component={'img'} src={logo} />
                            
                        </Box>
                        <Box className={classes.logo__cont}>
                            <Typography className={classes.h6} variant="h5">RespiMatters<Typography fontSize={8} component={'span'}>TM</Typography></Typography>
                            <Typography className={classes.p}>Institute for Applied Breathing Sciences </Typography>
                        </Box> */}
                    </Link>
                </Box>
                <Box sx={{
                    display:"flex",
                    gap:"1.5rem"
                }}>
                <Link className={classes.schedule__btn} to={'https://booking.respimatters.com/'} target="_black">  Individual Services</Link>
                <Link className={classes.schedule__btn} to={'/affiliates'} target="_black">  Affiliates</Link>

                </Box>
            </Box>

            <Box className={classes.mobresp}>

                <Box className={classes.mobhdr}>
                    <Box>
                        <Link className={classes.logo} to='/'>
                        <Box component={'img'} src={new_logo} sx={{
                        width:"220px"
                    }} />
                            {/* <Box>
                                <Box component={'img'} src={logo} />
                            </Box>
                            <Box className={classes.logo__cont}>
                                <Typography className={classes.h6} variant="h5">RespiMatters<Typography fontSize={8} component={'span'}>TM</Typography></Typography>
                                <Typography className={classes.p}>Institute for Applied Breathing Sciences</Typography>
                            </Box> */}
                        </Link>
                    </Box>
                    {open ?
                        <Box onClick={handleClose}>
                            <CloseIcon sx={{ color: '#000' }} />
                        </Box>
                        :

                        <Box sx={{ cursor: 'pointer' }} onClick={handleClickOpen}>
                            <MenuIcon />
                        </Box>
                    }


                </Box>

                {open &&
                    <Box
                        sx={{
                            // position: 'absolute',
                            top: '5.7rem',
                            zIndex: '1100',
                            backgroundColor: '#1480cb30',
                            textAlign: 'center',
                            width: '100%',
                            padding: '3rem 0rem',
                            transition: '3s',
                            
                        }}
                    >
                     <Box sx={{
                        display:"flex",
                        gap:"1rem",
                        justifyContent:"center",
                        flexDirection:"column"
                     }}>  <Link className={classes.schedule__btn} to={'https://booking.respimatters.com/'} target="_black">  Individual Services</Link>
                     <Link className={classes.schedule__btn} to={'/affiliates'} target="_black">  Affiliates</Link></Box>
                    </Box>

                }
            </Box>

        </>
    )
}

export default Header;